import React, { Fragment } from 'react';
import { Form, Col } from 'react-bootstrap';
import PlusX from '../PlusX';
import Switch from '../Switch';

export default function GuestConnectivityTab({ builtInHotspot, checked_builtInHotspot, setChecked_builtInHotspot, handleToggleHotspot, multipleEdit, checked_hotspotPassword, handleSetHotspotPassword,
    hotspotInterfaceInfoWidth, hotspotPassword, setChecked_hotspotPassword, requestedFrequency, irChannels, set_requestedFrequency, currentFrequency, clientIsolation, set_clientIsolation, guestAccessType,
    checked_guestAccessType, handleGuestAccessTypeChange, setChecked_guestAccessType,
    hotspotPasswordValid, deviceInfo, checked_clientIsolation, setChecked_clientIsolation}) {

    function getChannel(freq, width)
    {
        // Channel (Band) Width

        /* see 802.11 17.3.8.3.2 and Annex J */
        if (freq === 2484)
            return 14 + " (2.4 GHz) " + width;
        else if (freq < 2484)
            return ((freq - 2407) / 5) + " (2.4 GHz) " + width;
        else if (freq >= 4910 && freq <= 4980)
            return ((freq - 4000) / 5) + " (4.9 GHz) " + width;
        else if (freq < 5925)
            return ((freq - 5000) / 5) + " (5 GHz) " + width;
        else if (freq === 5935)
            return 2 + " (6 GHz) " + width;
        else if (freq <= 45000) /* DMG band lower limit */
            /* see 802.11ax D6.1 27.3.22.2 */
            return ((freq - 5950) / 5) + " (6 GHz) " + width;
        else if (freq >= 58320 && freq <= 70200)
            return ((freq - 56160) / 2160) + " (802.11ad) " + width;
        else
            return 0;
    }

    //function getBand(freq) {
    //    if (freq < 2495)
    //        return "2.4 GHz";
    //    else if (freq >= 3655 && freq <= 3695)
    //        return "3.65 GHz";
    //    else if (freq >= 4910 && freq < 4990)
    //        return "4.9 GHz";
    //    else if (freq > 5150 && freq < 5895)
    //        return "5 GHz";
    //    else if (freq > 5855 && freq < 5990)
    //        return "5.9 GHz";
    //    else if (freq > 5925 && freq < 7125)
    //        return "6 GHz";
    //    else return "";
    //}

    function getChannelListItem(freq, dfs) {
        let dfsSuffix = dfs ? " (DFS)" : ""

        if (freq === 2484)
            return 14 + " (2.4 GHz) " + dfsSuffix;
        else if (freq < 2484)
            return ((freq - 2407) / 5) + " (2.4 GHz) " + dfsSuffix;
        else if (freq >= 4910 && freq <= 4980)
            return ((freq - 4000) / 5) + " (4.9 GHz) " + dfsSuffix;
        else if (freq < 5925)
            return ((freq - 5000) / 5) + " (5 GHz) " + dfsSuffix;
        else if (freq === 5935)
            return 2 + " (6 GHz) " + dfsSuffix;
        else if (freq <= 45000) /* DMG band lower limit */
            /* see 802.11ax D6.1 27.3.22.2 */
            return ((freq - 5950) / 5) + " (6 GHz) " + dfsSuffix;
        else if (freq >= 58320 && freq <= 70200)
            return ((freq - 56160) / 2160) + " (802.11ad) " + dfsSuffix;
        else
            return 0;
    }

    function getChannelKey(freq) {
        return "freq-"+freq
    }

    function mapChannels() {
        let retval = [];
        retval.push(<option key='freq-0' value='0'>Automatic</option>);
        if (irChannels && Array.isArray(irChannels)) {
            //let lastBand = "";
            for (let i = 0; i < irChannels.length; i++) {
                let frequency = irChannels[i].frequency;
                let dfs = irChannels[i].dfs;
                //let band = getBand(frequency)

                //if (band !== lastBand) {
                //    if (lastBand !== "") {
                //        retval.push(</optgroup>)
                //    }
                //    lastBand = band;
                //    retval.push(<optgroup label='{band}'>)
                //}
                retval.push(<option key={getChannelKey(frequency)} value={frequency}>{getChannelListItem(frequency, dfs)}</option>)
            }
            //if (irChannels.length) {
            //    retval.push(</optgroup>)
            //}
        }
        return retval;
    }

    function mapGuestAccessType() {
        if (multipleEdit && !checked_guestAccessType) return null;
        let retval = [];
        retval.push(<option key='guestAccessTypeNone' value='0'>No Network</option>);
        retval.push(<option key='guestAccessTypeInternet' value='1'>Internet Only</option>);
        retval.push(<option key='guestAccessTypeFull' value='2'>Internet + LAN</option>);
        return retval;
    }

    function changeSelectedFrequency(e) {
        set_requestedFrequency(e.target.value);
    }

    function getHotspotPasswordClass() {
        let retval = hotspotPasswordValid ? '' : 'formIsInvalid';
        if (multipleEdit && !checked_hotspotPassword) retval += ' form-control-disabled'
        return retval;
    }

    return (
        <Form>
            <Form.Label className='tabLabel'>Guest Connectivity</Form.Label>
            <Form.Row className='tabInfoSection'>
                <Col>
                    {/* Wi-Fi information rows end here. */}
                    {
                        multipleEdit || !builtInHotspot ? null : (
                        <Fragment>
                            <Form.Row className='currentChannelRow'>
                                <Col className='col-auto mr-auto'><Form.Label>Current Frequency</Form.Label></Col>
                                <Col className='col-auto colCurrentChannel'>
                                        <Form.Label className='colorWhite'>{getChannel(currentFrequency, hotspotInterfaceInfoWidth)}</Form.Label>
                                </Col>
                            </Form.Row>
                        </Fragment>
                        )
                    }
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Group controlId="form_builtInHotspot">
                        <Form.Row>
                            <Col className='switchCol' xs='5'>
                                <Switch isOn={builtInHotspot} handleToggle={handleToggleHotspot} labelText='Wi-Fi Hotspot' visible={!(multipleEdit && !checked_builtInHotspot)} />
                            </Col>
                            <Col xs='1'>
                                <PlusX name='plusX_builtInHotspot' checked={checked_builtInHotspot} setChecked={setChecked_builtInHotspot} multipleEdit={multipleEdit} />
                            </Col>
                        </Form.Row>
                        <Form.Row style={!builtInHotspot || (multipleEdit && !checked_builtInHotspot) ? { display: 'none' } : null} className={multipleEdit ? '' : 'hotspotDiv'}>
                            <Col xs='6'>
                                <Form.Group>
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Wi-Fi Hotspot Password</Form.Label>
                                            <Form.Row>
                                                <Col xs={multipleEdit?10:12}>
                                                    <Form.Control type='text' value={hotspotPassword === null ? '' : hotspotPassword} onChange={e => handleSetHotspotPassword(e.target.value)}
                                                        placeholder={(multipleEdit && !checked_hotspotPassword) ? '' : 'Passphrase'} className={getHotspotPasswordClass()} disabled={multipleEdit && !checked_hotspotPassword} maxLength={63} />
                                                </Col>
                                                <Col>
                                                    <PlusX name='plusX_hotspotPassword' checked={checked_hotspotPassword} setChecked={setChecked_hotspotPassword} multipleEdit={multipleEdit} className='plusX_hotspotPassword' />
                                                </Col>
                                            </Form.Row>
                                      </Col>
                                    </Form.Row>
                                </Form.Group>


                                <Form.Group>
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Access</Form.Label>
                                            <Form.Row>
                                                <Col xs={multipleEdit ? 10 : 12}>
                                                    <Form.Control as='select' onChange={handleGuestAccessTypeChange} value={guestAccessType}>
                                                        {mapGuestAccessType()}
                                                     </Form.Control>
                                                </Col>
                                                <Col>
                                                    <PlusX name='plusX_GuestAccessType' checked={checked_guestAccessType} setChecked={setChecked_guestAccessType} multipleEdit={multipleEdit} />
                                                </Col>
                                            </Form.Row>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>

                                {
                                    multipleEdit ? null : (
                                        <Fragment>
                                            <Form.Group>
                                                <Form.Row>
                                                    <Col>
                                                        <Form.Label>Channel</Form.Label>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Col>
                                                        <Form.Control as="select" onChange={changeSelectedFrequency} value={requestedFrequency} >
                                                            { mapChannels() }
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Row>
                                            </Form.Group>
                                        </Fragment>
                                    )
                                }

                                 <Form.Group>
                                    <Form.Row>
                                        <Col className='switchCol' xs='10'>
                                            <Switch isOn={clientIsolation}
                                                handleToggle={() => { set_clientIsolation(!clientIsolation) }}
                                                labelText='Client Isolation' visible={!(multipleEdit && !checked_clientIsolation)} />
                                        </Col>
                                        <Col>
                                            <PlusX name='plusX_clientIsolation' checked={checked_clientIsolation} setChecked={setChecked_clientIsolation} multipleEdit={multipleEdit} className='plusX_clientIsolation' />
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Form>
    );
}
