import React, { Fragment } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import PlusX from '../PlusX';
import Switch from '../Switch';

export default function SecurityTab({ multipleEdit, sslPrivateKey, sslCertificate, set_sslPrivateKey, set_sslCertificate, checked_privateKeyAndCert, setChecked_privateKeyAndCert,
	enableDeviceManagement, set_enableDeviceManagement, checked_enableDeviceManagement, setChecked_enableDeviceManagement, deviceManagementPassword, handleChangeDeviceManagementPassword, checked_deviceManagementPassword,
	setChecked_deviceManagementPassword, deviceManagementPasswordValid }) {
	return (
		<Form>
			<Form.Label className='securityTabLabel'>Security</Form.Label>
			<Form.Group>
				<Row className='enableDeviceManagementRow'>
					<Col xs='6'>
						<Switch isOn={enableDeviceManagement} handleToggle={() => { set_enableDeviceManagement(!enableDeviceManagement) }}
							labelText='Device Management' visible={!(multipleEdit && !checked_enableDeviceManagement)} />
					</Col>
					<Col xs='6'>
						<PlusX name='plusX_edm' checked={checked_enableDeviceManagement} setChecked={setChecked_enableDeviceManagement}
							multipleEdit={multipleEdit} />
					</Col>
				</Row>
				<Row>
					{
						(!multipleEdit && enableDeviceManagement) || (multipleEdit && enableDeviceManagement && checked_enableDeviceManagement) ? (
							<Fragment>
								<Col xs='6'>
									<Form.Control type='password' value={deviceManagementPassword} id='devManPass' onChange={(e) => { handleChangeDeviceManagementPassword(e.target.value) }}
										placeholder={(multipleEdit && !checked_deviceManagementPassword) ? '' : 'Management password'} autoComplete='off' className={multipleEdit && !checked_deviceManagementPassword ? "form-control-disabled form-control" : "" }
										maxLength="150" disabled={multipleEdit && !checked_deviceManagementPassword} />
								</Col>
								<Col xs='6'>
									<PlusX name='plusX_dmp' checked={checked_deviceManagementPassword} setChecked={setChecked_deviceManagementPassword}
										multipleEdit={multipleEdit} />
								</Col>
							</Fragment>
						)
							: null
					}

				</Row>

				{
					(!multipleEdit && enableDeviceManagement && !deviceManagementPasswordValid) || (multipleEdit && enableDeviceManagement && checked_enableDeviceManagement && !deviceManagementPasswordValid) ? (
						<Col xs='12'>
							<Form.Label className='errorInputLong'>Device Management password cannot be empty</Form.Label>
						</Col>
					)
					: null
				}

				<Row>
					<Col xs='6' className='tlsLabelCol col-form-label-lg'>
						<Form.Label>TLS/SSL Security</Form.Label>
					</Col>
					<Col xs='6' className='tlsLabelCol col-form-label'>
						<PlusX name='plusX_privateKeyAndCert' checked={checked_privateKeyAndCert} setChecked={setChecked_privateKeyAndCert} multipleEdit={multipleEdit} className='plusX_privateKeyAndCert' />
					</Col>
				</Row>
				{
					multipleEdit && !checked_privateKeyAndCert ? null : (
						<Fragment>
							<Form.Row>
								<Col xs='12'>
									<Form.Label>Private Key</Form.Label>
									<Form.Control className="form-control-sm text-monospace" as='textarea' rows={6} value={sslPrivateKey === null ? '' : sslPrivateKey} onChange={(e) => { set_sslPrivateKey(e.target.value) }} placeholder="Enter private key" maxLength="4096" />
								</Col>
							</Form.Row>
							<Form.Row className='securityRow'>
								<Col xs='12'>
									<Form.Label>Certificate</Form.Label>
									<Form.Control className="form-control-sm text-monospace" as='textarea' rows={13} value={sslCertificate === null ? '' : sslCertificate} onChange={(e) => { set_sslCertificate(e.target.value) }} placeholder="Enter certificate" maxLength="4096" />
								</Col>
							</Form.Row>
						</Fragment>
					)
				}

				{
					(!multipleEdit || (multipleEdit && checked_privateKeyAndCert)) && (!sslPrivateKey || !sslCertificate) ? (
						<Col xs='12'>
							<Form.Label className='errorInputLong'>Private Key and Certificate must not be empty</Form.Label>
						</Col>
					)
					: null
				}
			</Form.Group>
		</Form>
	);
}
